import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import getBalanceTransferOfferDurationMonths from './getBalanceTransferOfferDurationMonths'

export function getCardHasOfferDuration(
    creditCard: ScoredCreditCardProduct | null
) {
    if (creditCard == null) {
        return false
    }

    const balanceTransferOfferDuration =
        getBalanceTransferOfferDurationMonths(creditCard)

    return (
        creditCard.offerDetail.durationOfPurchaseRateMonths != null ||
        balanceTransferOfferDuration != null
    )
}
