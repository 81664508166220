import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
// @ts-ignore
import TickIcon from '@totallymoney/ui/icons/Tick'
import TooltipIcon from '@totallymoney/ui/icons/Tooltip'
import theme from '@totallymoney/ui/theme'
import React from 'react'

import { StyledPreApprovedIndicator } from './PreApprovedIndicator.styled'

interface PreApprovedIndicatorProps {
    id: string
    apr: number | string
    onPress: () => void
}

const PreApprovedIndicator = ({
    id,
    apr,
    onPress,
}: PreApprovedIndicatorProps) => {
    return (
        <StyledPreApprovedIndicator
            as="button"
            display="flex"
            onClick={onPress}
            justifyContent={['center', 'unset']}
        >
            <Box
                display="flex"
                flexDirection="column"
                width={['100%', '208px']}
                borderRadius="borderRadius10"
                bg="eligibilityLevel0-bg10"
                maxWidth={['220px']}
            >
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    p="space025"
                    bg={theme.preApprovedGreen}
                    borderRadius="borderRadius10"
                >
                    <TickIcon
                        size={13}
                        color="white"
                        title={`Pre approved tick for ${id}`}
                    />
                    <Box p="space05">
                        <Text
                            variant="80"
                            color="white"
                            textAlign="center"
                            style={{ fontFamily: theme.buenosAires }}
                        >
                            Pre-approved
                        </Text>
                    </Box>
                    <TooltipIcon
                        size={20}
                        color="white"
                        title={`Pre approved tooltip for ${id}`}
                    />
                </Box>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    bg="eligibilityLevel0-bg10"
                    py="space10"
                    borderBottomLeftRadius="borderRadius10"
                    borderBottomRightRadius="borderRadius10"
                >
                    <Text
                        variant="60"
                        textAlign="center"
                        style={{ color: theme.graphiteDarker }}
                    >
                        <>
                            At <b>{`${apr}%`} APR</b> <br />
                            (subject to final checks)
                        </>
                    </Text>
                </Box>
            </Box>
        </StyledPreApprovedIndicator>
    )
}

export default PreApprovedIndicator
