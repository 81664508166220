interface Options {
    dailyId?: string
    permanentId?: string
    sessionId?: string
}

function getAuthHeaders(options?: Options) {
    const headers: Record<string, string> = {}

    if (options == null) {
        return headers
    }

    if (options.sessionId) {
        headers['X-SessionId'] = options.sessionId
    }

    if (options.dailyId) {
        headers['X-DailyId'] = options.dailyId
    }

    if (options.permanentId) {
        headers['X-PermanentId'] = options.permanentId
    }

    return headers
}

export default getAuthHeaders
