import { ContentProps } from './Content.interface'

const ContentRepresentativeExample = ({
    creditCard,
    renderProductDetail,
}: ContentProps) => {
    const representativeAPR = creditCard.offerDetail.representativeAPR

    if (representativeAPR == null || representativeAPR <= 0) {
        return null
    }

    return renderProductDetail({
        title: `Representative % APR (${
            creditCard.isFixedRate ? 'fixed' : 'variable'
        })`,
        value: `${representativeAPR.toFixed(1)}% APR`,
    })
}

export default ContentRepresentativeExample
