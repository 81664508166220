import { Formik, FormikHelpers } from 'formik'

import useAppQueryState from '@hooks/useAppQueryState'
import useLogEvents from '@hooks/useLogEvents'

import { useMinimumGuaranteedCreditLimitMutation } from '@queries/useMinimumGuaranteedCreditLimitMutation'
import { useMinimumGuaranteedCreditLimitQuery } from '@queries/useMinimumGuaranteedCreditLimitQuery'

import {
    MinimumCreditLimitModalFormSchema,
    MinimumCreditLimitModalFormValues,
} from '../MinimumCreditLimitModal.validation'
import MinimumCreditLimitModalForm from '../MinimumCreditLimitModalForm'

interface MinimumCreditLimitModalFormWrapperProps {
    onModalClose: () => void
}

const MinimumCreditLimitModalFormWrapper = ({
    onModalClose,
}: MinimumCreditLimitModalFormWrapperProps) => {
    const logEvents = useLogEvents()
    const { appQueryState } = useAppQueryState()

    const minimumGuaranteedCreditLimitResponse =
        useMinimumGuaranteedCreditLimitQuery({
            customerId: appQueryState.customerId!,
        })
    const { mutateAsync } = useMinimumGuaranteedCreditLimitMutation()

    async function onUpdatePress(
        values: MinimumCreditLimitModalFormValues,
        formikHelpers: FormikHelpers<MinimumCreditLimitModalFormValues>
    ) {
        const minCreditLimit = values.minCreditLimitUpdate
        if (minCreditLimit == null) {
            return
        }

        formikHelpers.setStatus(undefined)

        try {
            await mutateAsync({
                minimumGuaranteedCreditLimit: Number(minCreditLimit),
                customerId: appQueryState.customerId!,
            })

            logEvents.logMinCreditLimitSubmit({
                minCreditLimit,
                source: 'creditCardsMinCreditLimitUpdateModal',
            })

            onModalClose()
        } catch (err) {
            formikHelpers.setStatus(
                'Failed to set your ideal credit limit. Please try again.'
            )
        }
    }

    return (
        <Formik
            onSubmit={onUpdatePress}
            initialValues={
                {
                    minCreditLimitUpdate:
                        minimumGuaranteedCreditLimitResponse.data
                            ?.minimumGuaranteedCreditLimit,
                } as unknown as MinimumCreditLimitModalFormValues
            }
            validateOnChange={true}
            validationSchema={MinimumCreditLimitModalFormSchema}
        >
            <MinimumCreditLimitModalForm onSkip={onModalClose} />
        </Formik>
    )
}

export default MinimumCreditLimitModalFormWrapper
