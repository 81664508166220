import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { getCategoryPathForId } from './getCategoryPathForId'

/**
 * For unscored journeys, we want to use / as the alias
 * for the /all-cards route.
 *
 * @param category - The credit card category
 * @returns The unscored category path
 */
export function getUnscoredCategoryPath(category: CreditCardProductCategory) {
    const isBestCards = category === CreditCardProductCategory.BestCards

    return isBestCards ? '' : getCategoryPathForId(category)
}
