import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import { getSanitisedCardName } from './getSanitisedCardName'

interface GetCardByCategoryOptions {
    cardId: string
    category: CreditCardProductCategory
}

export function getCardByCategory(
    scoredCategories: ScoredCreditCardProductsCategory[],
    options: GetCardByCategoryOptions
) {
    if (!Array.isArray(scoredCategories)) {
        return null
    }

    const activeCategory = scoredCategories.find(
        category => category.id === options.category
    )

    return activeCategory?.creditCards.find(
        product =>
            getSanitisedCardName(product.name) ===
            getSanitisedCardName(options.cardId)
    )
}
