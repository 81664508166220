export const eligibilityForCreditProductFaqUrl =
    'https://help.totallymoney.com/en/articles/165224-what-does-my-eligibility-for-a-credit-product-mean'

export const notEnoughInformationFaqUrl =
    'https://help.totallymoney.com/en/articles/165176-why-isn-t-there-enough-information-in-my-file-and-why-can-t-i-see-any-credit-results'

export const whatIsPreapprovedFaqUrl =
    'https://help.totallymoney.com/en/articles/165228-what-does-pre-approved-mean'

export const cardsFaqsUrl =
    'https://help.totallymoney.com/en/collections/315267-credit-cards'
