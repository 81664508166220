import theme from '@totallymoney/ui/theme'

import {
    SemanticEligibilityScore,
    getSemanticEligibilityScore,
} from './getSemanticEligibilityScore'

export const getEligibilityVariantOptions = (score?: number) => {
    const semanticScore = getSemanticEligibilityScore(score)

    switch (semanticScore) {
        case SemanticEligibilityScore.NotRated: {
            return {
                boxBg: theme.grey20,
                indicatorBoxBg: theme.grey60,
                text: semanticScore,
            }
        }
        case SemanticEligibilityScore.NotEligible: {
            return {
                boxBg: theme.grey20,
                indicatorBoxBg: theme.grey60,
                text: semanticScore,
            }
        }
        case SemanticEligibilityScore.Poor: {
            return {
                boxBg: theme['eligibilityLevel4-bg20'],
                indicatorBoxBg: theme.eligibilityLevel4,
                text: `${SemanticEligibilityScore.Poor} eligibility`,
            }
        }
        case SemanticEligibilityScore.Average: {
            return {
                boxBg: theme['eligibilityLevel3-bg20'],
                indicatorBoxBg: theme.eligibilityLevel3,
                text: `${SemanticEligibilityScore.Average} eligibility`,
            }
        }
        case SemanticEligibilityScore.Good: {
            return {
                boxBg: theme['eligibilityLevel2-bg20'],
                indicatorBoxBg: theme.eligibilityLevel2,
                text: `${semanticScore} eligibility`,
            }
        }
        case SemanticEligibilityScore.VeryGood: {
            return {
                boxBg: theme['eligibilityLevel1-bg20'],
                indicatorBoxBg: theme.eligibilityLevel1,
                percent: score,
                text: `${semanticScore} eligibility`,
            }
        }
        case SemanticEligibilityScore.Excellent: {
            return {
                boxBg: theme['eligibilityLevel1-bg20'],
                indicatorBoxBg: theme.eligibilityLevel1,
                percent: score,
                text: `${semanticScore} eligibility`,
            }
        }
        case SemanticEligibilityScore.PreApproved: {
            return {
                boxBg: theme['eligibilityLevel1-bg20'],
                indicatorBoxBg: theme.eligibilityLevel1,
                percent: score,
                text: semanticScore,
            }
        }
        default: {
            return {
                boxBg: theme.grey20,
                indicatorBoxBg: theme.grey60,
                text: SemanticEligibilityScore.NotRated,
            }
        }
    }
}
