import { getAppConfig } from '@utils/getAppConfig'

interface Options {
    queryObject?: {
        ccid?: string
        card?: string
        category?: string
    }
    queryString?: string
}

export function getRedirectAccountUrl(params: Options = {}) {
    const appConfig = getAppConfig()

    const { queryObject = {}, queryString = '' } = params

    const formBaseUrl = appConfig.ACCOUNT_URL

    let formUrl = new URL(formBaseUrl + queryString)

    const pathname = `/myaccount/register/`
    const productTableQuery = {
        features: 'product_table_journey',
        pt: 'cards',
    }

    formUrl.pathname = pathname

    const searchParamsObject = {
        ...queryObject,
        ...productTableQuery,
        client: appConfig.NAME_WHITELABEL,
        do: `${
            appConfig.BASE_FORM_URL
        }${process.env.NEXT_PUBLIC_BASE_PATH.slice(1)}/`,
    }

    for (const [key, value] of Object.entries(searchParamsObject)) {
        if (value != null) {
            formUrl.searchParams.append(key, value)
        }
    }

    // make sure search is empty .. otherwise it ignores the query object
    formUrl.searchParams.delete('search')
    // make sure we don't pass sort key to form; we don't want this persisted
    formUrl.searchParams.delete('sortby')

    return formUrl.toString()
}
