import { QueryParametersAppState } from './getAppStateFromQueryParameters'

export function getIsDormantScoreIdJourney(
    appQueryState: QueryParametersAppState
) {
    return (
        appQueryState.dormantScoreId != null &&
        appQueryState.customerId != null &&
        appQueryState.category != null
    )
}
