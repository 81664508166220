import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

function getBalanceTransferOfferDurationMonths(
    creditCard: ScoredCreditCardProduct
) {
    return (
        creditCard.balanceTransferOfferDurationMonths ??
        creditCard.offerDetail.durationOfBalanceRateMonths
    )
}

export default getBalanceTransferOfferDurationMonths
