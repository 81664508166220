import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import Field from '@totallymoney/ui/components/Field'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import TextField from '@totallymoney/ui/components/TextField'
import theme from '@totallymoney/ui/theme'
import { useFormikContext } from 'formik'
import Image from 'next/legacy/image'

import formatNumberToCurrency from '@utils/formatNumberToCurrency'

import { minimumCreditLimitRange } from '@config/minimumCreditLimitRange'

import insightsLightBulb from '../../../../public/images/insights-lightbulb.svg'
import { MinimumCreditLimitModalFormValues } from '../MinimumCreditLimitModal.validation'

interface MinimumCreditLimitModalFormProps {
    onSkip: () => void
}

const MinimumCreditLimitModalForm = ({
    onSkip,
}: MinimumCreditLimitModalFormProps) => {
    const {
        setFieldValue,
        setFieldTouched,
        submitForm,
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        status,
    } = useFormikContext<MinimumCreditLimitModalFormValues>()

    return (
        <Box display="flex" flexDirection="column" alignItems="space-between">
            <Stack space="space30" pb={['space50', 'space160']}>
                <Heading variant="80">
                    Enter a personalised credit limit
                </Heading>
                <Field
                    helperText={`${formatNumberToCurrency(
                        minimumCreditLimitRange.min
                    )} - ${formatNumberToCurrency(
                        minimumCreditLimitRange.max
                    )}`}
                    labelText="Minimum credit limit"
                    name="MinimumCreditLimit"
                >
                    <TextField
                        name="minCreditLimitUpdate"
                        prefix="£"
                        autoComplete="off"
                        autoCapitalize="none"
                        placeholder="0"
                        value={values.minCreditLimitUpdate ?? ''}
                        error={!!errors.minCreditLimitUpdate}
                        data-testid="minCreditLimitUpdate"
                        touched={!!touched.minCreditLimitUpdate}
                        validationMessage={errors.minCreditLimitUpdate}
                        pattern="\d*"
                        onChange={e =>
                            setFieldValue(
                                'minCreditLimitUpdate',
                                e.target.value
                            )
                        }
                        onBlur={() => {
                            setFieldTouched('minCreditLimitUpdate', true)
                        }}
                    />
                </Field>
                <Box display="flex" flexDirection="row">
                    <Box mr="space10">
                        <Image
                            src={insightsLightBulb}
                            alt="Light bulb with a light indigo background"
                        />
                    </Box>
                    <Box flex={1}>
                        <Text color="dark" variant="100">
                            <span style={{ fontWeight: 'bold' }}>Tip:</span>{' '}
                            Enter a credit limit based on existing balance or
                            spending plans, and we’ll show you the lenders who
                            can offer what you’ve requested
                        </Text>
                    </Box>
                </Box>
            </Stack>
            {status ? (
                <Box mb="space20">
                    <Text
                        style={{ color: theme.alertError120 }}
                        textAlign="center"
                    >
                        {status}
                    </Text>
                </Box>
            ) : null}
            <Box
                display="grid"
                flexDirection="column"
                gridGap="22px"
                alignSelf="center"
                width={['100%', '384px']}
            >
                <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!dirty}
                    loading={isSubmitting}
                >
                    Update
                </Button>
                <Button
                    variant="secondaryOutline"
                    onClick={onSkip}
                    disabled={isSubmitting}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    )
}

export default MinimumCreditLimitModalForm
