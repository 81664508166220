import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

function getShowRepresentativeExample(
    creditCard: ScoredCreditCardProduct
): boolean {
    return (
        creditCard.guaranteedRate?.guaranteedAPR == null &&
        creditCard.offerDetail.representativeAPR != null &&
        creditCard.offerDetail.representativeAPR > 0
    )
}

export default getShowRepresentativeExample
