import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import AlertIconBadge from '@totallymoney/ui/icons/AlertIconBadge'

const ContentPreApprovedAndGuaranteedApr = () => {
    return (
        <Stack space="space40">
            <Stack space="space20">
                <Box display="flex" alignItems="center">
                    <Box mr="space20">
                        <AlertIconBadge />
                    </Box>
                    <Heading variant="60">
                        What does ’pre-approved’ mean?
                    </Heading>
                </Box>
                <Text color="light">
                    Pre-approval is subject to credit checks. If you apply,
                    you’ll get the card or loan, at the rate advertised,
                    providing the information you give us is accurate and you
                    pass the lender’s final checks.
                </Text>
            </Stack>

            <Stack space="space20">
                <Box display="flex" alignItems="center">
                    <Box mr="space20">
                        <AlertIconBadge />
                    </Box>
                    <Heading variant="60">
                        What does guaranteed APR mean?
                    </Heading>
                </Box>
                <Text color="light">
                    APR refers to the annual percentage rate, which is how much
                    you’ll pay in interest and lender fees. If the offer’s rate
                    is guaranteed, that’s what you’ll get offered if you’re
                    accepted — no surprises.
                </Text>
            </Stack>
        </Stack>
    )
}

export default ContentPreApprovedAndGuaranteedApr
