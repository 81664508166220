import React from 'react'

import { getFormattedMonth } from '@utils/formatter'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'

import { GuaranteedOfferType } from '@appTypes/App.interface'

import getShowGuaranteedExample from '../utils/getShowGuaranteedExample'
import getShowRepresentativeExample from '../utils/getShowRepresentativeExample'
import { ContentProps, ProductDetailType } from './Content.interface'
import ContentGuaranteedExample from './ContentGuaranteedExample'
import ContentMinimumGuaranteedCreditLimit from './ContentMinimumGuaranteedCreditLimit'
import ContentNoMinimumGuaranteedCreditLimit from './ContentNoMinimumGuaranteedCreditLimit'
import ContentRepresentativeExample from './ContentRepresentativeExample'

const ContentZeroPurchase = ({
    creditCard,
    isMediaLargePlus,
    renderProductDetail,
    creditLimitProductDetailVariant,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: ContentProps) => {
    const { guaranteedCreditLimit } = creditCard.guaranteedRate
    const monthDisplay = isMediaLargePlus ? 'long' : 'short'

    const isMarketingOffer =
        getGuaranteedOfferType({
            creditCard,
        }) === GuaranteedOfferType.MarketingOffer

    return (
        <>
            {renderProductDetail({
                title: '0% Purchase offer duration',
                value: getFormattedMonth(
                    creditCard.offerDetail.durationOfPurchaseRateMonths,
                    monthDisplay
                ),
                detailType: isMarketingOffer
                    ? ProductDetailType.GuaranteedExample
                    : undefined,
            })}
            {getShowGuaranteedExample(creditCard) ? (
                <ContentGuaranteedExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {getShowRepresentativeExample(creditCard) ? (
                <ContentRepresentativeExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {guaranteedCreditLimit != null &&
            creditLimitProductDetailVariant !== 'setLimit' ? (
                <ContentMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {creditLimitProductDetailVariant != null ? (
                <ContentNoMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                    creditLimitProductDetailVariant={
                        creditLimitProductDetailVariant
                    }
                    onShowCreditLimitModal={onShowCreditLimitModal}
                    onShowMinimumCreditLimitModal={
                        onShowMinimumCreditLimitModal
                    }
                />
            ) : null}
        </>
    )
}

export default ContentZeroPurchase
