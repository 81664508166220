import React, { useMemo } from 'react'

import { getCreditLimitProductDetailVariant } from '@utils/getCreditLimitProductDetailVariant'
import { getMediaLargePlus } from '@utils/mediaQueries'

import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'
import { GetGuaranteedCreditLimitResponse } from '@appTypes/PagesApi.interface'

import { ContentProps, ProductDetail } from './components/Content.interface'
import ContentBalanceAndPurchase from './components/ContentBalanceAndPurchase'
import ContentBalanceTransfer from './components/ContentBalanceTransfer'
import ContentBestCards from './components/ContentBestCards'
import ContentCashback from './components/ContentCashback'
import ContentPoorCredit from './components/ContentPoorCredit'
import ContentRewards from './components/ContentRewards'
import ContentTravel from './components/ContentTravel'
import ContentZeroPurchase from './components/ContentZeroPurchase'

interface CreditCardItemContentProps {
    category: CreditCardProductCategory
    creditCard: ScoredCreditCardProduct
    renderProductDetail: (detail: ProductDetail) => React.ReactElement
    minCreditLimitData?: GetGuaranteedCreditLimitResponse | null
    onShowCreditLimitModal?: () => void
    onShowMinimumCreditLimitModal?: () => void
}

const contentMap: Record<
    CreditCardProductCategory,
    React.FunctionComponent<ContentProps>
> = {
    [CreditCardProductCategory.BestCards]: ContentBestCards,
    [CreditCardProductCategory.Cashback]: ContentCashback,
    [CreditCardProductCategory.BalanceTransfer]: ContentBalanceTransfer,
    [CreditCardProductCategory.PoorCredit]: ContentPoorCredit,
    [CreditCardProductCategory.Purchase]: ContentZeroPurchase,
    [CreditCardProductCategory.Combined]: ContentBalanceAndPurchase,
    [CreditCardProductCategory.Rewards]: ContentRewards,
    [CreditCardProductCategory.Travel]: ContentTravel,
}

const CreditCardItemContent = ({
    creditCard,
    category,
    renderProductDetail,
    minCreditLimitData,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: CreditCardItemContentProps) => {
    const ItemContent = contentMap[category] || ContentBestCards

    const creditLimitProductDetailVariant = getCreditLimitProductDetailVariant(
        creditCard,
        {
            hasMinCreditLimitSet: minCreditLimitData?.isExpired == false,
        }
    )

    const isMediaLargePlus = useMemo(() => {
        return getMediaLargePlus()
    }, [])

    return (
        <ItemContent
            creditCard={creditCard}
            category={category}
            isMediaLargePlus={isMediaLargePlus}
            renderProductDetail={renderProductDetail}
            creditLimitProductDetailVariant={creditLimitProductDetailVariant}
            onShowCreditLimitModal={onShowCreditLimitModal}
            onShowMinimumCreditLimitModal={onShowMinimumCreditLimitModal}
        />
    )
}

export default CreditCardItemContent
