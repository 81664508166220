import Modal from '@totallymoney/ui/components/Modal'

import { GuaranteedOfferType } from '@appTypes/App.interface'

import ContentGuaranteedApr from './components/ContentGuaranteedApr'
import ContentGuaranteedAprAndCreditLimit from './components/ContentGuaranteedAprAndCreditLimit'
import ContentMarketingOffer from './components/ContentMarketingOffer'
import ContentPreApprovedAndGuaranteedApr from './components/ContentPreApprovedAndGuaranteedApr'

interface GuaranteedOfferModalProps {
    showOfferDurationContent: boolean
    offerType?: GuaranteedOfferType | null
    onModalClose: () => void
    showModal: boolean
}

const offerTypeContent = {
    [GuaranteedOfferType.GuaranteedApr]: ContentGuaranteedApr,
    [GuaranteedOfferType.GuaranteedAprAndCreditLimit]:
        ContentGuaranteedAprAndCreditLimit,
    [GuaranteedOfferType.MarketingOffer]: ContentMarketingOffer,
    [GuaranteedOfferType.PreApprovedAndGuaranteedApr]:
        ContentPreApprovedAndGuaranteedApr,
    [GuaranteedOfferType.CreditLimit]: null,
    [GuaranteedOfferType.GuaranteedCreditLimit]: null,
    [GuaranteedOfferType.PreApprovedAndGuaranteedCreditLimit]: null,
} as const

export function getHasOfferTypeContent(offerType: GuaranteedOfferType | null) {
    if (offerType == null) {
        return false
    }
    return offerTypeContent[offerType] != null
}

const GuaranteedOfferModal = ({
    showOfferDurationContent,
    offerType,
    showModal,
    onModalClose,
}: GuaranteedOfferModalProps) => {
    const OfferTypeContent =
        offerType != null ? offerTypeContent[offerType] : null

    return (
        <Modal
            hasHeader={true}
            hasTooltipHeader={false}
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={true}
            hasMinHeight={false}
        >
            {OfferTypeContent != null ? (
                <OfferTypeContent
                    showOfferDurationContent={showOfferDurationContent}
                />
            ) : null}
        </Modal>
    )
}

export default GuaranteedOfferModal
