import * as yup from 'yup'

import formatNumberToCurrency from '@utils/formatNumberToCurrency'

import { minimumCreditLimitRange } from '@config/minimumCreditLimitRange'

const CREDIT_LIMIT_MESSAGE = `Enter credit limit between ${formatNumberToCurrency(
    minimumCreditLimitRange.min
)} - ${formatNumberToCurrency(minimumCreditLimitRange.max)}`

export const MinimumCreditLimitModalFormSchema = yup.object().shape({
    minCreditLimitUpdate: yup
        .number()
        .nullable()
        .typeError(CREDIT_LIMIT_MESSAGE)
        .required(CREDIT_LIMIT_MESSAGE)
        .integer(CREDIT_LIMIT_MESSAGE)
        .min(minimumCreditLimitRange.min, CREDIT_LIMIT_MESSAGE)
        .max(minimumCreditLimitRange.max, CREDIT_LIMIT_MESSAGE),
})

export type MinimumCreditLimitModalFormValues = yup.InferType<
    typeof MinimumCreditLimitModalFormSchema
>
