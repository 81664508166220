import AnimatedLoader from '@totallymoney/ui/components/AnimatedLoader'
import Box from '@totallymoney/ui/components/Box'

interface AnimatedLoadingPageProps {
    heading?: string
    supportingText?: string | string[]
    fill?: boolean
}

const AnimatedLoadingPage = ({
    heading = 'Just a sec! We’re finding your best cards.',
    supportingText = [
        'Checking your credit profile',
        'Finding the best products for you',
        'Ordering your results',
    ],
    fill = true,
}: AnimatedLoadingPageProps) => {
    return (
        <Box zIndex={2} position="relative">
            {fill ? <Box height="100vh" /> : null}
            <Box position="absolute" top={0} left={0}>
                <AnimatedLoader
                    heading={heading}
                    supportingText={supportingText}
                />
            </Box>
        </Box>
    )
}

export default AnimatedLoadingPage
