import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import { getRepresentativeExampleTextForCard } from '@utils/getRepresentativeExampleTextForCard'

import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import TooltipButton from '@components/TooltipButton'

interface RepresentativeExampleProps {
    item: ScoredCreditCardProduct
    onTooltipPress: () => void
}

const RepresentativeExample = ({
    item,
    onTooltipPress,
}: RepresentativeExampleProps) => {
    return (
        <Box>
            <Text variant="100" color="light">
                <strong>Representative Example</strong>{' '}
                <Box
                    as="span"
                    verticalAlign="middle"
                    ml="space05"
                    mr="space10"
                    display="inline-flex"
                >
                    <TooltipButton
                        data-testid="representative-example-tooltip"
                        title={`Tooltip icon for representative example - ${item.productCode}`}
                        aria-label="What is Representative Example?"
                        color={theme.grey100}
                        onPress={onTooltipPress}
                    />
                </Box>
                {getRepresentativeExampleTextForCard(item)}
            </Text>
        </Box>
    )
}

export default RepresentativeExample
