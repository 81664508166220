import React from 'react'

import getShowGuaranteedExample from '../utils/getShowGuaranteedExample'
import getShowRepresentativeExample from '../utils/getShowRepresentativeExample'
import { ContentProps } from './Content.interface'
import ContentGuaranteedCreditLimit from './ContentGuaranteedCreditLimit'
import ContentGuaranteedExample from './ContentGuaranteedExample'
import ContentMinimumGuaranteedCreditLimit from './ContentMinimumGuaranteedCreditLimit'
import ContentNoMinimumGuaranteedCreditLimit from './ContentNoMinimumGuaranteedCreditLimit'
import ContentRepresentativeExample from './ContentRepresentativeExample'

const ContentRewards = ({
    creditCard,
    renderProductDetail,
    creditLimitProductDetailVariant,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: ContentProps) => {
    const { guaranteedCreditLimit } = creditCard.guaranteedRate

    return (
        <>
            {getShowGuaranteedExample(creditCard) ? (
                <ContentGuaranteedExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {getShowRepresentativeExample(creditCard) ? (
                <ContentRepresentativeExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {guaranteedCreditLimit != null &&
            creditLimitProductDetailVariant !== 'setLimit' ? (
                <ContentMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {creditLimitProductDetailVariant != null ? (
                <ContentNoMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                    creditLimitProductDetailVariant={
                        creditLimitProductDetailVariant
                    }
                    onShowCreditLimitModal={onShowCreditLimitModal}
                    onShowMinimumCreditLimitModal={
                        onShowMinimumCreditLimitModal
                    }
                />
            ) : null}
        </>
    )
}

export default ContentRewards
