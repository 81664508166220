import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import * as React from 'react'

import TooltipButton from '@components/TooltipButton'

import { ContentProps, ProductDetailType } from './Content.interface'

const ContentNoMinimumGuaranteedCreditLimit = ({
    creditLimitProductDetailVariant,
    renderProductDetail,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: ContentProps) => {
    return renderProductDetail({
        title: (
            <Box
                mb={[0, 'space10']}
                display="flex"
                flexDirection="row"
                style={{ whiteSpace: 'nowrap' }}
            >
                <Box pr="space10">
                    <Text variant="100" color="light">
                        Credit limit
                    </Text>
                </Box>
                <TooltipButton
                    size={20}
                    color={theme.grey100}
                    onPress={onShowCreditLimitModal!}
                    title="Tooltip icon for credit limit"
                />
            </Box>
        ),
        value: (
            <Box>
                {creditLimitProductDetailVariant === 'application' ? (
                    <Text
                        variant="120"
                        weight="bold"
                        textAlign="left"
                        color="light"
                        style={{ fontFamily: theme.buenosAires }}
                    >
                        Upon application
                    </Text>
                ) : null}
                {creditLimitProductDetailVariant === 'setLimit' ? (
                    <Button
                        variant="tertiaryTransparent"
                        onClick={onShowMinimumCreditLimitModal}
                        style={{
                            maxHeight: 'unset',
                            minHeight: 'unset',
                            paddingBottom: theme.space05,
                            fontSize: '18px',
                        }}
                    >
                        Enter limit
                    </Button>
                ) : null}
            </Box>
        ),
        detailType:
            creditLimitProductDetailVariant === 'setLimit'
                ? ProductDetailType.setLimit
                : undefined,
    })
}

export default ContentNoMinimumGuaranteedCreditLimit
