const formatter = new Intl.NumberFormat('en-GB', {
    currency: 'GBP',
    style: 'currency',
    maximumFractionDigits: 2,
})

function formatNumberToCurrency(number?: number) {
    if (number == null) {
        return '£0'
    }

    const formatted = formatter.format(number)

    return formatted.replace('.00', '')
}

export default formatNumberToCurrency
