import { GuaranteedOfferType } from '@appTypes/App.interface'
import {
    GuaranteedRateOfferType,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

interface Options {
    creditCard: ScoredCreditCardProduct | null
}

function getGuaranteedOfferType({
    creditCard,
}: Options): GuaranteedOfferType | null {
    if (creditCard == null) {
        return null
    }

    const { isPreApproved } = creditCard
    const hasGuaranteedApr = creditCard.guaranteedRate.guaranteedAPR != null
    const hasGuaranteedCreditLimit =
        creditCard.guaranteedRate.guaranteedCreditLimit != null

    if (isPreApproved && hasGuaranteedApr && hasGuaranteedCreditLimit) {
        return GuaranteedOfferType.MarketingOffer
    }

    if (isPreApproved && hasGuaranteedApr) {
        return GuaranteedOfferType.PreApprovedAndGuaranteedApr
    }

    if (isPreApproved && hasGuaranteedCreditLimit) {
        return GuaranteedOfferType.PreApprovedAndGuaranteedCreditLimit
    }

    if (hasGuaranteedApr && hasGuaranteedCreditLimit) {
        return GuaranteedOfferType.GuaranteedAprAndCreditLimit
    }

    if (hasGuaranteedApr) {
        return GuaranteedOfferType.GuaranteedApr
    }

    if (hasGuaranteedCreditLimit) {
        return GuaranteedOfferType.GuaranteedCreditLimit
    }

    if (
        creditCard.guaranteedRate.guaranteedOfferType != null &&
        [
            GuaranteedRateOfferType.HasGuaranteedLimit,
            GuaranteedRateOfferType.ApplyForGuaranteedLimit,
        ].includes(creditCard.guaranteedRate.guaranteedOfferType)
    ) {
        return GuaranteedOfferType.CreditLimit
    }

    return null
}

export default getGuaranteedOfferType
