import {
    GuaranteedRateOfferType,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

interface Options {
    hasMinCreditLimitSet?: boolean
}

export const getCreditLimitProductDetailVariant = (
    card: ScoredCreditCardProduct,
    options: Options
): 'application' | 'setLimit' | null => {
    const guaranteedOfferType = card.guaranteedRate?.guaranteedOfferType

    if (Object.keys(card.guaranteedRate).length === 0) {
        return null
    }

    if (
        guaranteedOfferType === GuaranteedRateOfferType.ApplyForGuaranteedLimit
    ) {
        return 'application'
    }

    if (
        guaranteedOfferType === GuaranteedRateOfferType.HasGuaranteedLimit &&
        !options.hasMinCreditLimitSet
    ) {
        return 'setLimit'
    }

    if (card.guaranteedRate?.guaranteedCreditLimit == null) {
        return 'application'
    }

    return null
}
