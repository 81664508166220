import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import React, { useEffect, useRef } from 'react'

import { eligibilityForCreditProductFaqUrl } from '@constants/helpLinks'

import {
    PercentageScoreContainer,
    ScoreBarInner,
} from './EligibilityIndicator.styled'
import { getEligibilityVariantOptions } from './utils/getEligibilityVariantOptions'

interface EligibilityIndicatorProps {
    score?: number
}

const EligibilityIndicator = ({
    score,
}: EligibilityIndicatorProps): React.ReactElement => {
    const variantOptions = getEligibilityVariantOptions(score)
    const [transition, setTranstion] = React.useState(0)

    const refContainer = useRef<HTMLAnchorElement | null>(null)

    const checkVisible = () => {
        if (refContainer.current == null) {
            return
        }

        var rect = refContainer.current.getBoundingClientRect()
        var viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight
        )
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }

    useEffect(
        function mount() {
            function onScroll() {
                if (refContainer != null && score != null && checkVisible()) {
                    setTranstion(score)
                }
            }
            if (refContainer != null && score != null && checkVisible()) {
                setTranstion(score)
            }
            window.addEventListener('scroll', onScroll)
            return function unMount() {
                window.removeEventListener('scroll', onScroll)
            }
        },
        [score]
    )

    return (
        <PercentageScoreContainer
            ref={refContainer}
            href={eligibilityForCreditProductFaqUrl}
            target="_blank"
        >
            <Box p="5px">
                <Text
                    weight="bold"
                    textAlign="center"
                    variant="80"
                    style={{ fontFamily: theme.buenosAires }}
                >
                    {variantOptions.text}
                </Text>
            </Box>
            <Box
                backgroundColor="#eeeeee"
                height="10px"
                width="100%"
                borderTopLeftRadius="borderRadius20"
                borderBottomLeftRadius="borderRadius20"
                borderTopRightRadius="borderRadius20"
                borderBottomRightRadius="borderRadius20"
            >
                <ScoreBarInner
                    data-testid="eligibilityIndicatorScoreBar"
                    backgroundColor={variantOptions.indicatorBoxBg}
                    width={`${transition}%`}
                    height="10px"
                    borderTopLeftRadius="borderRadius20"
                    borderBottomLeftRadius="borderRadius20"
                />
            </Box>
            {score != null ? (
                <Box p="5px">
                    <Text
                        weight="bold"
                        textAlign="center"
                        variant="60"
                        style={{ fontFamily: theme.buenosAires }}
                    >
                        {`${score}% chance of acceptance`}
                    </Text>
                </Box>
            ) : null}
        </PercentageScoreContainer>
    )
}

export default EligibilityIndicator
