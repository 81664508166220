import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import AlertIconBadge from '@totallymoney/ui/icons/AlertIconBadge'

const GuaranteedAprExplanation = () => {
    return (
        <Stack space="space20">
            <Box display="flex" alignItems="center">
                <Box mr="space20">
                    <AlertIconBadge />
                </Box>
                <Heading variant="60">What does guaranteed APR mean?</Heading>
            </Box>
            <Text color="light">
                APR refers to the annual percentage rate, which is how much
                you’ll pay in interest and lender fees. If the offer’s rate is
                guaranteed, that’s what you’ll get offered if you’re accepted —
                no surprises.
            </Text>
        </Stack>
    )
}

export default GuaranteedAprExplanation
