import Box from '@totallymoney/ui/components/Box'
import theme from '@totallymoney/ui/theme'
import styled from 'styled-components'

interface PercentageScoreContainerProps {
    inCard?: boolean
}

export const PercentageScoreContainer = styled.a<PercentageScoreContainerProps>`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: ${theme.radii.borderRadius10};
    margin: auto;
    text-decoration: none;
    font-family: ${theme.buenosAires};
    width: 100%;

    ${theme.mediaMediumPlus`
        margin: auto;
        width: 220px;
    `}
`

export const ScoreBarInner = styled(Box)`
    transition: width 0.6s ease-out;
`
