import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

export enum ProductDetailType {
    GuaranteedExample = 'GuaranteedExample',
    GuaranteedCreditLimit = 'GuaranteedCreditLimit',
    setLimit = 'setLimit',
}

export interface ProductDetail {
    title: string | React.ReactElement
    value: string | React.ReactElement
    detailType?: ProductDetailType
}

export interface ContentProps {
    creditCard: ScoredCreditCardProduct
    category?: CreditCardProductCategory
    isMediaLargePlus?: boolean
    renderProductDetail: (detail: ProductDetail) => React.ReactElement
    creditLimitProductDetailVariant?: 'application' | 'setLimit' | null
    onShowCreditLimitModal?: () => void
    onShowMinimumCreditLimitModal?: () => void
}
