import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
    SetMinimumGuaranteedCreditLimitRequest,
    SetMinimumGuaranteedCreditLimitResponse,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

import { handleApiResponse } from './utils/handleApiResponse'

export const useMinimumGuaranteedCreditLimitMutation = () => {
    const queryClient = useQueryClient()
    const { appQueryState } = useAppQueryState()

    return useMutation<
        SetMinimumGuaranteedCreditLimitResponse,
        void,
        SetMinimumGuaranteedCreditLimitRequest
    >(
        body =>
            fetch(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/minimum-guaranteed-credit-limit/`,
                {
                    body: JSON.stringify(body),
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                }
            ).then(handleApiResponse),
        {
            onSuccess: async data => {
                return await Promise.all([
                    queryClient.setQueryData(
                        [
                            'minimumGuaranteedCreditLimit',
                            appQueryState.customerId,
                        ],
                        data
                    ),
                    queryClient.invalidateQueries({
                        queryKey: [
                            'scoredCategories',
                            appQueryState.scoreId,
                            appQueryState.customerId,
                        ],
                    }),
                ])
            },
        }
    )
}
