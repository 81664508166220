import React from 'react'

import { getFormattedAPR, getFormattedMonth } from '@utils/formatter'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'

import { GuaranteedOfferType } from '@appTypes/App.interface'
import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import getBalanceTransferOfferDurationMonths from '../../../utils/getBalanceTransferOfferDurationMonths'
import getShowGuaranteedExample from '../utils/getShowGuaranteedExample'
import getShowRepresentativeExample from '../utils/getShowRepresentativeExample'
import { ContentProps, ProductDetailType } from './Content.interface'
import { getGuaranteedCreditLimitValue } from './ContentGuaranteedCreditLimit'
import ContentGuaranteedExample from './ContentGuaranteedExample'
import ContentMinimumGuaranteedCreditLimit from './ContentMinimumGuaranteedCreditLimit'
import ContentNoMinimumGuaranteedCreditLimit from './ContentNoMinimumGuaranteedCreditLimit'
import ContentRepresentativeExample from './ContentRepresentativeExample'

const ContentBalanceTransfer = ({
    creditCard,
    isMediaLargePlus,
    renderProductDetail,
    creditLimitProductDetailVariant,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: ContentProps) => {
    const balanceTransferOfferDurationMonths =
        getBalanceTransferOfferDurationMonths(creditCard)

    const monthDisplay = isMediaLargePlus ? 'long' : 'short'

    const guaranteedCreditLimit = getGuaranteedCreditLimitValue({
        creditCard,
        category: CreditCardProductCategory.BalanceTransfer,
    })

    const isMarketingOffer =
        getGuaranteedOfferType({
            creditCard,
        }) === GuaranteedOfferType.MarketingOffer

    return (
        <>
            {renderProductDetail({
                title: 'Balance Transfer offer duration',
                value: getFormattedMonth(
                    balanceTransferOfferDurationMonths,
                    monthDisplay
                ),
                detailType: isMarketingOffer
                    ? ProductDetailType.GuaranteedExample
                    : undefined,
            })}
            {getShowGuaranteedExample(creditCard) ? (
                <ContentGuaranteedExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {getShowRepresentativeExample(creditCard) ? (
                <ContentRepresentativeExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {guaranteedCreditLimit != null &&
            creditLimitProductDetailVariant !== 'setLimit' ? (
                <ContentMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {creditLimitProductDetailVariant != null ? (
                <ContentNoMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    creditLimitProductDetailVariant={
                        creditLimitProductDetailVariant
                    }
                    renderProductDetail={renderProductDetail}
                    onShowCreditLimitModal={onShowCreditLimitModal}
                    onShowMinimumCreditLimitModal={
                        onShowMinimumCreditLimitModal
                    }
                />
            ) : null}
            {renderProductDetail({
                title: 'Transfer Fee',
                value: getFormattedAPR(
                    creditCard.offerDetail.introBalanceTransferFee
                ),
            })}
        </>
    )
}

export default ContentBalanceTransfer
