import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import Image from 'next/legacy/image'

import iconGreenPhone from '../../../public/images/icon-green-phone.png'
import iconPoundHand from '../../../public/images/icon-pound-hand.png'
import iconPurplePercent from '../../../public/images/icon-purple-percent.png'
import iconWomanExaminePhone from '../../../public/images/icon-woman-examine-phone.png'

interface ContentMarketingOfferProps {
    showOfferDurationContent: boolean
}

const ContentMarketingOffer = ({
    showOfferDurationContent,
}: ContentMarketingOfferProps) => {
    return (
        <Stack space="space20" flex={1}>
            <Heading variant="80">What’s a TotallySure offer?</Heading>
            <Box pb="space20">
                {showOfferDurationContent ? (
                    <Text color="dark" variant="100">
                        Be TotallySure with pre-approval and up to four
                        guarantees, including a guaranteed credit limit and a
                        guaranteed rate, if accepted. Look for offers with the
                        green banner.
                    </Text>
                ) : (
                    <Text color="dark" variant="100">
                        Be TotallySure with pre-approval, a guaranteed credit
                        limit and a guaranteed rate, if approved. Look for
                        offers with the green banner.
                    </Text>
                )}
            </Box>
            <Stack space="space30">
                <Box display="flex" flexDirection="row">
                    <Box>
                        <Image
                            src={iconGreenPhone}
                            alt="Green phone with a tick on it on a cream background"
                            width={44}
                            height={40}
                        />
                    </Box>
                    <Stack space="space05" pl="space20" flex={1}>
                        <Heading color="dark" variant="40">
                            Pre-approval
                        </Heading>
                        <Text color="light" variant="100">
                            If you apply, you’ll get the card or loan, at the
                            rate advertised, providing the information you give
                            us is accurate and you pass the lender’s final
                            checks.
                        </Text>
                    </Stack>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box>
                        <Image
                            src={iconPurplePercent}
                            alt="Purple percentage sign on a light red background"
                            width={44}
                            height={40}
                        />
                    </Box>
                    <Stack space="space05" pl="space20" flex={1}>
                        <Heading color="dark" variant="40">
                            Guaranteed % APR
                        </Heading>
                        <Text color="light" variant="100">
                            APR stands for annual percentage rate, which is how
                            much interest and lender fees you’ll pay. If it’s
                            guaranteed, that’s what you’ll get offered if
                            accepted — no surprises.
                        </Text>
                    </Stack>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box>
                        <Image
                            src={iconPoundHand}
                            alt="Pound sign hovering above hand on a blue background"
                            width={44}
                            height={40}
                        />
                    </Box>
                    <Stack space="space05" pl="space20" flex={1}>
                        <Heading color="dark" variant="40">
                            Min. Guaranteed Credit Limit
                        </Heading>
                        <Text color="light" variant="100">
                            This is the minimum amount of money a lender can
                            guarantee you before you apply. Note, you’ll still
                            need to be accepted for the card.
                        </Text>
                    </Stack>
                </Box>
                {showOfferDurationContent ? (
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Image
                                src={iconWomanExaminePhone}
                                alt="Pound sign hovering above hand on a blue background"
                                width={44}
                                height={40}
                            />
                        </Box>
                        <Stack space="space05" pl="space20" flex={1}>
                            <Heading color="dark" variant="40">
                                Offer durations
                            </Heading>
                            <Stack space="space20" flex={1}>
                                <Text color="light" variant="100">
                                    Some cards come with 0% interest offers, for
                                    a certain time period.
                                </Text>
                                <Text color="light" variant="100">
                                    <strong>Balance transfer cards</strong>{' '}
                                    allow you to pay off a balance you’ve
                                    transferred, interest-free. Fees may apply.
                                </Text>
                                <Text color="light" variant="100">
                                    <strong>Purchase cards</strong> mean you can
                                    spend interest-free for as long as the offer
                                    lasts.
                                </Text>
                                <Text color="light" variant="100">
                                    If either of these offers are guaranteed, as
                                    long as you’re accepted, you’ll get the
                                    length shown to you before you apply.
                                </Text>
                                <Text color="light" variant="100">
                                    Some cards have both a purchase offer and a
                                    balance transfer offer — and each could be
                                    guaranteed, on the same card!
                                </Text>
                            </Stack>
                        </Stack>
                    </Box>
                ) : null}
            </Stack>
        </Stack>
    )
}

export default ContentMarketingOffer
