import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

interface RepresentativeExampleModalProps {
    onModalClose: () => void
    showModal: boolean
}

const RepresentativeExampleModal = ({
    showModal,
    onModalClose,
}: RepresentativeExampleModalProps) => {
    return (
        <Modal
            title="Representative Example and Representative APR."
            hasHeader={false}
            hasTooltipHeader={true}
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={true}
            hasMinHeight={false}
        >
            <Stack space="space10">
                <Text variant="100" color="light">
                    <strong>The Representative Example</strong> gives you an
                    idea of how much it could cost if you borrowed an example
                    amount. This helps you compare different products and
                    provides a guide on how much taking out credit could cost.
                    Your personal offer may be different to this, so make sure
                    you read the details of any offer a lender makes to you.
                </Text>
                <Text variant="100" color="light">
                    <strong>Your representative APR</strong> is the annualised
                    rate of interest you will be charged on any balances that
                    incur interest charges. The Representative APR refers to the
                    rate that at least 51% of those accepted for the product
                    will get.
                </Text>
            </Stack>
        </Modal>
    )
}

export default RepresentativeExampleModal
