import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import { getFormattedCurrency } from '@utils/formatter'

import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { ContentProps, ProductDetailType } from './Content.interface'

export function getMinimumGuaranteedCreditLimitValue({
    creditCard,
    category,
}: Omit<ContentProps, 'renderProductDetail'>) {
    if (
        category === CreditCardProductCategory.BalanceTransfer &&
        creditCard.balanceTransferComboLimit
    ) {
        return creditCard.balanceTransferComboLimit
    }
    if (
        creditCard.balanceTransferComboLimit &&
        creditCard.guaranteedRate.guaranteedCreditLimit
    ) {
        return (
            creditCard.balanceTransferComboLimit +
            creditCard.guaranteedRate.guaranteedCreditLimit
        )
    }
    return creditCard.guaranteedRate.guaranteedCreditLimit
}

const ContentMinimumGuaranteedCreditLimit = ({
    creditCard,
    category,
    renderProductDetail,
}: ContentProps) => {
    const value = getMinimumGuaranteedCreditLimitValue({ creditCard, category })
    const isCounterOffer = creditCard.guaranteedRate?.isCounterOffer ?? false

    return renderProductDetail({
        title: (
            <Stack space="space025">
                <Text variant="100" color="light">
                    Min. Guaranteed Credit Limit
                </Text>
                {isCounterOffer ? (
                    <Text variant="60" color="light">
                        *lower than requested
                    </Text>
                ) : null}
            </Stack>
        ),
        value: `${getFormattedCurrency(value)}${isCounterOffer ? '*' : ''}`,
        detailType: ProductDetailType.GuaranteedCreditLimit,
    })
}

export default ContentMinimumGuaranteedCreditLimit
