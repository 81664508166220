import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import {
    getFormattedCurrency,
    getFormattedInterestRate,
    truncateToOneDecimalPlace,
} from './formatter'

const getCreditLimitAmount = (
    balanceTransferComboLimit?: number,
    guaranteedCreditLimit?: number,
    representativeExampleCreditLimit?: number
): string => {
    return getFormattedCurrency(
        guaranteedCreditLimit || representativeExampleCreditLimit
    )
}

export const getRepresentativeExampleTextForCard = ({
    balanceTransferComboLimit,
    guaranteedRate: { guaranteedAPR, guaranteedCreditLimit },
    rateType,
    provider,
    specificRepExampleText,
    offerDetail: {
        annualFee,
        purchaseAER,
        representativeAPR,
        representativeExampleCreditLimit,
        useGuaranteedAPR,
    },
}: ScoredCreditCardProduct): string => {
    if (specificRepExampleText) {
        return specificRepExampleText
    } else {
        const amount = getCreditLimitAmount(
            balanceTransferComboLimit,
            guaranteedCreditLimit,
            representativeExampleCreditLimit
        )

        const interestRate =
            (provider.toLowerCase() === 'newday' || useGuaranteedAPR) &&
            guaranteedAPR
                ? getFormattedInterestRate(guaranteedAPR)
                : getFormattedInterestRate(purchaseAER)

        const representativeAprValue = truncateToOneDecimalPlace(
            guaranteedAPR || representativeAPR
        )

        const exampleText =
            `If you spend ${amount} at a purchase interest rate of ${interestRate}% p.a. (${rateType})` +
            (annualFee
                ? ` with an annual fee of ${getFormattedCurrency(annualFee)}`
                : '') +
            ` your representative APR will be ${representativeAprValue}% APR (${rateType})`

        return exampleText
    }
}
