import Box from '@totallymoney/ui/components/Box'
import Modal from '@totallymoney/ui/components/Modal'

import MinimumCreditLimitModalFormWrapper from './components/MinimumCreditLimitModalFormWrapper'

interface MinimumCreditLimitModalProps {
    onModalClose: () => void
    showModal: boolean
}

const MinimumCreditLimitModal = ({
    showModal,
    onModalClose,
}: MinimumCreditLimitModalProps) => {
    return (
        <Modal isFullScreen={true} isOpen={showModal} onClose={onModalClose}>
            <Box display="flex" justifyContent="center">
                <Box maxWidth="384px">
                    <MinimumCreditLimitModalFormWrapper
                        onModalClose={onModalClose}
                    />
                </Box>
            </Box>
        </Modal>
    )
}

export default MinimumCreditLimitModal
