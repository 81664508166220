import theme from '@totallymoney/ui/theme'

export const getMediaLargePlus = () => {
    if (typeof window === 'undefined') {
        return false
    }

    if (window.matchMedia == null) {
        return false
    }

    return window?.matchMedia(`(min-width: ${theme.medium + 1}px)`).matches
}
