import ButtonWithTooltip from '@totallymoney/ui/components/ButtonWithTooltip'

import { GuaranteedOfferType } from '@appTypes/App.interface'

interface GuaranteedOfferQuestionProps {
    offerType: GuaranteedOfferType
    onTooltipPress: () => void
}

const GuaranteedOfferQuestion = ({
    offerType,
    onTooltipPress,
}: GuaranteedOfferQuestionProps) => {
    const questionText =
        offerType === GuaranteedOfferType.MarketingOffer
            ? 'What’s a TotallySure offer?'
            : 'What’s a guaranteed offer?'

    return (
        <ButtonWithTooltip
            direction="left"
            text={questionText}
            onClick={onTooltipPress}
        />
    )
}

export default GuaranteedOfferQuestion
