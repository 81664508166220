import Box from '@totallymoney/ui/components/Box'
import Text, { TextProps } from '@totallymoney/ui/components/Text'

interface UnorderedListProps {
    children: React.ReactNode
}

const UnorderedList = ({ children }: UnorderedListProps) => {
    return (
        <Box
            as="ul"
            display="grid"
            gridGap="space10"
            pl="space30"
            style={{ listStyleType: 'disc' }}
        >
            {children}
        </Box>
    )
}

export const ListItem = ({
    children,
    textColor = 'light',
}: {
    children: React.ReactNode
    textColor?: TextProps['color']
}) => {
    return (
        <Box as="li" pl="space10">
            <Text color={textColor}>{children}</Text>
        </Box>
    )
}

export default UnorderedList
