import { getAppConfig } from '@utils/getAppConfig'

import {
    ApplicationURLTriggerLocation,
    CreditCardProductCategory,
} from '@appTypes/EligibilityApi.interface'

import { decodePrePopToken } from './decodePrePopToken'

export interface GetCreditCardApplicationUrlOptions {
    clickSource: string
    cam?: string
    category: CreditCardProductCategory
    client?: string
    customerId: string
    dailyId: string
    featured: boolean
    isBestMatchCard: boolean
    isKnownCustomer: string
    k?: string
    m?: string
    sendId?: string
    gclid?: string
    permanentId: string
    prepopulationToken?: string
    productCode: string
    productIndex: number
    provider: string
    score: number
    scoreId: string
    status: string
    thirdPartyReference: string
    trigger: ApplicationURLTriggerLocation
    visitGuid: string
    guaranteedApr?: number
    guaranteedCreditLimit?: number
}

export const getCardApplicationUrl = (
    options: GetCreditCardApplicationUrlOptions
): string => {
    const appConfig = getAppConfig()

    const searchQuery = {
        csrc: options.clickSource,
        ccid: options.productCode,
        status: options.status,
        hdscore: options.score,
        customerOrigin: 'Account',
        customerId: options.customerId,
        customerType: 'Account',
        isKnownCustomer: options.isKnownCustomer,
        client: options.client ?? 'web',
        scoreId: options.scoreId,
        visitGuid: options.visitGuid,
        dailyId: options.dailyId,
        permanentId: options.permanentId,
        m: options.m,
        cam: options.cam,
        k: options.k,
        sendId: options.sendId,
        gclid: options.gclid,
        category: options.category,
        provider: options.provider,
        trigger: options.trigger,
        rowNumber: options.productIndex,
        isBestMatchCard: options.isBestMatchCard || '',
        guaranteedApr: options.guaranteedApr,
        guaranteedCreditLimit: options.guaranteedCreditLimit,
        platform: 'web',
        ...(options.featured && { featured: true }),
    } as const

    const url = new URL(appConfig.CLICK_OUT_URL)

    Object.keys(searchQuery).forEach(searchKey => {
        const sk = searchKey as keyof typeof searchQuery

        if (searchQuery[sk] != null) {
            url.searchParams.append(
                searchKey,
                searchQuery[sk] as unknown as string
            )
        }
    })

    return `${url.toString()}${
        options.thirdPartyReference ? `&${options.thirdPartyReference}` : ''
    }&pt=${options.prepopulationToken ? options.prepopulationToken : ''}`
}
