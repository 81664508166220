import { useQuery } from '@tanstack/react-query'

import { ScoredCreditCardProductsCategory } from '@appTypes/EligibilityApi.interface'

import { handleApiResponse } from './utils/handleApiResponse'

interface Options<TSelectResult> {
    initialData?: ScoredCreditCardProductsCategory[]
    select?: (
        cardsResponse: ScoredCreditCardProductsCategory[]
    ) => TSelectResult
}

export const useUnscoredCategoriesQuery = <TSelectResult>({
    initialData,
    select,
}: Options<TSelectResult>) => {
    return useQuery(
        ['unscoredCategories'],
        () =>
            fetch(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/unscored/`).then(
                handleApiResponse
            ),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: 2,
            initialData,
            select,
        }
    )
}
