import { useQuery } from '@tanstack/react-query'

import { GetMinimumGuaranteedCreditLimitResponse } from '@appTypes/EligibilityApi.interface'

import { handleApiResponse } from './utils/handleApiResponse'

interface Options {
    customerId: string
    shouldFetchGuaranteedCreditLimit?: boolean
}

export const useMinimumGuaranteedCreditLimitQuery = ({
    customerId,
    shouldFetchGuaranteedCreditLimit = true,
}: Options) => {
    return useQuery<GetMinimumGuaranteedCreditLimitResponse>(
        ['minimumGuaranteedCreditLimit', customerId],
        () =>
            fetch(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/minimum-guaranteed-credit-limit/?customerId=${customerId}`,
                {
                    method: 'GET',
                }
            ).then(handleApiResponse),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            enabled: shouldFetchGuaranteedCreditLimit,
        }
    )
}
