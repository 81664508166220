import Box from '@totallymoney/ui/components/Box'
import LinkWithChevron from '@totallymoney/ui/components/LinkWithChevron'
import Modal from '@totallymoney/ui/components/Modal'
import Text from '@totallymoney/ui/components/Text'

import { whatIsPreapprovedFaqUrl } from '@constants/helpLinks'

interface PreApprovedModalProps {
    onModalClose: () => void
    showModal: boolean
}

const PreApprovedModal = ({
    showModal,
    onModalClose,
}: PreApprovedModalProps) => {
    return (
        <Modal
            title="What does ‘Pre-approved’ mean?"
            hasHeader={false}
            hasTooltipHeader={true}
            isOpen={showModal}
            onClose={() => onModalClose()}
            isFullScreen={true}
            hasMinHeight={false}
        >
            <>
                <Box ml="5px" mb="space15">
                    <Text>
                        Pre-approval is subject to credit checks. If you apply,
                        you’ll get the card or loan, at the rate advertised,
                        providing the information you give us is accurate and
                        you pass the lender’s final checks.
                    </Text>
                </Box>
                <Box ml="5px" mb="space10">
                    <LinkWithChevron
                        href={whatIsPreapprovedFaqUrl}
                        variant="100"
                        target="_blank"
                    >
                        Find out more here
                    </LinkWithChevron>
                </Box>
            </>
        </Modal>
    )
}

export default PreApprovedModal
