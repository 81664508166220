import { getCardHasOfferDuration } from '@utils/getCardHasOfferDuration'

import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

interface Options {
    category: CreditCardProductCategory
    card: ScoredCreditCardProduct | null
}

const offerDurationCategories = [
    CreditCardProductCategory.BestCards,
    CreditCardProductCategory.Purchase,
    CreditCardProductCategory.BalanceTransfer,
    CreditCardProductCategory.Combined,
]

export function getShowOfferDurationContent({ category, card }: Options) {
    const hasOfferDurationCategory = offerDurationCategories.includes(category)
    return hasOfferDurationCategory && getCardHasOfferDuration(card)
}
