import React, { ErrorInfo, ReactNode } from 'react'

interface OnErrorData {
    error: Error
    errorInfo: ErrorInfo
}

interface ErrorBoundaryProps {
    children: ReactNode
    fallback?: ReactNode | (({ error }: { error: Error }) => ReactNode)
    onError?: (errorData: OnErrorData) => void
}

interface ErrorBoundaryState {
    error: Error | null
}

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    public state: ErrorBoundaryState = {
        error: null,
    }

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.props.onError?.({
            error,
            errorInfo,
        })
    }

    public render() {
        if (this.state.error != null) {
            if (typeof this.props.fallback === 'function') {
                return this.props.fallback({
                    error: this.state.error,
                })
            }

            return this.props.fallback
        }

        return this.props.children
    }
}

export default ErrorBoundary
